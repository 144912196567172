import Home from './Home';


function App() {
  return (
    <div>
      <Home/>
  </div>
  );
}

export default App;
