import * as firebase from 'firebase';

const config = {
  apiKey: "AIzaSyAAsKW4T0eybGtuCDijf9u8Gg1tOJ0vSHM",
  authDomain: "starbucks-polling.firebaseapp.com",
  projectId: "starbucks-polling",
  storageBucket: "starbucks-polling.appspot.com",
  messagingSenderId: "362838264040",
  appId: "1:362838264040:web:c2b7e9e29230ef1da9215d",
  measurementId: "G-CTMMS6JKDB"
};

firebase.initializeApp(config)
export const database = firebase.firestore();
export default firebase;
