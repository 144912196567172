import React from 'react';
import firstcontent from './images/first-content.svg';
import brewyourown from './images/brewyourown.png';
import secondcontent from './images/second-content.svg';
import logo from './images/logo-new.svg';
import headerwhite from './images/header-white.png';
import './Home.css';
import firebase,{ database } from './firebase';

class Home extends  React.Component
{

    constructor(props){
        super(props);
        this.state={
            contestants:[
                {ID:"Raj Patel", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2Fra_starbucks.jpg?alt=media&token=8bfec4b7-4f0c-4e67-b71c-f5978f6692bf", ShortDescription:"Double Cocoa Praline", ShortDescription1:"Raj Patel",LongDescription:"A rocking twist to the classic Java Chip loaded with ferrero rocher, hazelnut syrup and whipped cream with a java chip drizzle.", URL:"", Active:true, Selected:false},
                {ID:"Hetvi Trivedi", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2F1starbucks.png?alt=media&token=cd793d81-f046-4da7-8d59-3a355427f422", ShortDescription:"Cocoa Hazelnut",  ShortDescription1:"Hetvi Trivedi", LongDescription:"A delicious blend of white mocha with hazelnut, chocolate chips, caramel drizzle that's topped with whipped cream.", URL:"", Active:true, Selected:false},
                {ID:"Rahul Keswani", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2F3Starbucks.jpg?alt=media&token=547aadfb-7150-4191-9aa4-ef18383a144c", ShortDescription:"Chocolately Caramel Bliss", ShortDescription1:"Rahul Keswani", LongDescription:"A signature mocha blend loaded with caramel and whipped cream.", Active:true, Selected:false},
                
                {ID:"Shivam Mishra", 
                Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2Fshivam_starbucks.png?alt=media&token=9fa15f6e-c6a0-4de5-a11e-466aba0755b7", 
                ShortDescription:"Caramel Hazelnut Bliss", 
                ShortDescription1:"Shivam Mishra",
                LongDescription:"A delectable combination of vanilla and hazelnut that's topped with cocoa powder and a java chip drizzle on the whipped cream.", URL:"", Active:true, Selected:false},
                
                {ID:"Sathvika", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FSathvika.png?alt=media&token=2734ee6d-6666-489f-84b1-7feb47090076", 
                ShortDescription:"Vanilla Java Chip Delight", ShortDescription1:"Sathvika",LongDescription:"A vanilla-flavoured mocha delight topped with whipped cream and delicious java chips.", URL:"", Active:true, Selected:false},
                
                {ID:"Binita Das", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2F4_1Starbucks.jpg?alt=media&token=f587803a-f830-4222-8717-02b1edae09dd", 
                ShortDescription:"Turkish Hazelnut Caramel Infusion", ShortDescription1:"Binita Das", LongDescription:"The decadent caramel-hazelut combination topped with some chocolate flakes, coffee powder, whipped cream and a chocolate drizzle.", URL:"", Active:true, Selected:false},
                
                {ID:"Vyomil Patel", 
                Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FVyomil%20Patel.png?alt=media&token=0bfd4c7e-e011-4535-8875-e4fdea835c90", 
                ShortDescription:"Cocoa Hazelnut Infusion", 
                ShortDescription1:"Vyomil Patel",
                LongDescription:"A stunning mocha and hazelnut blend that's loaded with java chips, topped with whipped cream and garnished with a mocha drizzle.", 
                URL:"", Active:true, Selected:false},

                // {ID:"Simar Chhabra", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2F2starbucks.png?alt=media&token=96fda9b8-6c6d-4478-a3e8-fcd31eb37c21", ShortDescription:"Caramel Mocha", ShortDescription1:"Simar Chhabra", 
                // LongDescription:"A refreshingly delicious combination of caramel and mocha topped with whipped cream and garnished with java chips.", 
                // URL:"", Active:true, Selected:false},
                
                 {ID:"Vibhuti Koli", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FScreenshot%202022-09-27%20at%207.09.42%20PM-min.png?alt=media&token=3c0009e9-984b-44ac-8944-1e92a0e59a56", 
                ShortDescription:"Double Chocolate Delight", 
                ShortDescription1:"Vibhuti Koli",
                LongDescription:"A vanilla-hazelnut combination loaded with the decadence of chocolate crumbs, java chips and whipped cream.", URL:"", Active:true, Selected:false},

                
                {ID:"Aastha Chawla", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FAstha.png?alt=media&token=2ac52c44-c0db-4805-8951-caba301f04ef", 
                ShortDescription:"Mocha Choco Chip Praline", ShortDescription1:"Aastha Chawla", 
                LongDescription:"A classic mocha loaded with a delicious hazelnut sauce, topped with whipped cream and yummy chocolate chips.", URL:"", Active:true, Selected:false},

                {ID:"Abhishek Nair", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FVibhuti_starbucks-min.png?alt=media&token=6def02cc-9061-471a-a3fa-b83bd4748cf4", 
                ShortDescription:"Cocoa Vanilla Infusion", ShortDescription1:"Abhishek Nair",LongDescription:"The goodness of mocha combined with classic vanilla and java chips, topped with whipped cream and a caramel drizzle.", URL:"", Active:true, Selected:false},
               
                {ID:"Solomon", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FSolom.png?alt=media&token=c1ace5eb-6962-44b3-98b9-cbaafbe9114d", 
                ShortDescription:" Cafe Hazelnut Mocha", ShortDescription1:"Solomon", LongDescription:"A hazelnut-infused decadent mocha topped with whipped cream and a mocha drizzle.", URL:"", Active:true, Selected:false},
               

               
                
                {ID:"Paurvi Kaul", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2FPuravi.png?alt=media&token=a64ad7d1-f1f0-46d1-909a-000b6cabd397", 
                ShortDescription:"Caramel Java Chip Praline ",ShortDescription1:"Paurvi Kaul", LongDescription:"A hazelnut and caramel loaded rendition of the classic Java Chip topped with whipped cream and a light mocha drizzle.", URL:"", Active:true, Selected:false},

               
                
                // {ID:"C13", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2Flogo-new.svg?alt=media&token=64a67522-f52d-40d9-835d-8ad09240e7b1", ShortDescription:"", LongDescription:"", URL:"", Active:false, Selected:false},
                // {ID:"C14", Image :"https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/1Starbucks%2Flogo-new.svg?alt=media&token=64a67522-f52d-40d9-835d-8ad09240e7b1", ShortDescription:"", LongDescription:"", URL:"", Active:false, Selected:false}
            ],
            showThankYouMsg:false
        }
        this.renderContestant = this.renderContestant.bind(this);
        this.onVoteClick = this.onVoteClick.bind(this);
        this.onSubmitClick =this.onSubmitClick.bind(this);
        this.objToCSV = this.objToCSV.bind(this);
    }

    
    objToCSV(obj) {
    const csvRows = [];
    const headers = Object.keys(obj[0]);
    csvRows.push(headers.join(","));
  
    for (const row of obj) {
      const values = headers.map(header => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
  
    return csvRows.join("\n");
  };

    async componentDidMount(){
        // let result =[]
        // result = await database.collection("StarbucksPolls").get().then((querySnapshot) => {
        //     console.log(querySnapshot.docs.length);
        //     const data = []
        //     querySnapshot.forEach((doc) => {
        //         data.push({Contestant:doc.data().ID})
        //     })
        //     return data;
        // }).catch((error) => { console.log(error) })


        // const csvData = this.objToCSV(result);
        // const csvBlob = new Blob([csvData], { type: "text/csv" });
        // const url = window.URL.createObjectURL(csvBlob);
        // const a = document.createElement("a");
        // a.setAttribute("hidden", "");
        // a.setAttribute("href", url);
        // a.setAttribute("download", "contesntant.csv");
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    }

    onVoteClick(contestant,index){

        let contestants = this.state.contestants;
        contestants[index].Selected = !contestants[index].Selected ;
        this.setState({contestant:contestants});
    }

    async onSubmitClick(){
        let contestants = this.state.contestants;
        let userID = Math.random().toString(16).slice(2);
        contestants.map(async (m)=>{
            if(m.Selected){
                // console.log(m)
                await database.collection("StarbucksPolls").add({
                    ID: m.ID,
                    UserID:userID,
                    CreatedOn: firebase.firestore.FieldValue.serverTimestamp()
                  })
            }
        })
        this.setState({showThankYouMsg:true})
    }

    renderContestant(contestant, index){
        if(contestant.Active){
            return <div className='contestant'>
                <img src={contestant.Image} onClick={()=>{
                    if(contestant.URL !==""){
                        window.open(contestant.URL, '_blank').focus();

                    }
                }}/>
                <div className='text'>
                    <div className='shortDescription'>{contestant.ShortDescription}</div>
                    <div className='longDescription'>By {contestant.ShortDescription1}</div>
                    <div className='longDescription'>{contestant.LongDescription}</div>
                </div>
                <div className='button'>
                    <button className={contestant.Selected ?  ' selected-contestant' : ''} onClick={()=>this.onVoteClick(contestant, index)}>Vote</button>
                </div>
            </div>
        }
        else
        {
            return <React.Fragment>
                
                <div className='contestant'>
                <div className="inactive">
                    <span className="">Coming Soon</span>
                </div>
                {/* <img src={contestant.Image}/>
                <div className='text'>
                    <div className='shortDescription'>{contestant.ShortDescription}</div>
                    <div className='longDescription'>{contestant.LongDescription}</div>
                </div>
                <div className='button'>
                    <button className={contestant.Selected ?  ' selected-contestant' : ''} onClick={()=>this.onVoteClick(contestant, index)}>Vote</button>
                </div> */}
            </div>
            </React.Fragment>
        }
    }

    render(){
        return <React.Fragment>
            <div className='pattern logo'>
                    <img src={logo} alt="logo"/>
                </div>
                <div className='pattern headerwhite'>
                    <img src={headerwhite} alt="headerwhite"/>
                </div>
                <div className='pattern banner-first'>
                    <img src={firstcontent} alt="firstcontent"/>
                </div>

                <div className='pattern brewyourown'>
                            <img src={brewyourown} alt="brewyourownstarbucks"/>
                </div>

                {!this.state.showThankYouMsg && <React.Fragment>

                    <div className='container'>
                        
                    <div className='header-text'>
                        {/* <h1> */}
                            Vote for the beverage you loved the most!
                            {/* </h1> */}
                       
                    </div>
                    <div className='sub-header-text'>
                        <ul>
                            <li>
                            Pick your favourite beverage amongst our semi-finalists and help bring the ultimate one on the official Starbucks Menu.
                            </li>
                            <li>
                            Feel free to vote for more than one.
                            </li>
                            <li>
                            Click on your favourites to select them and simply press Submit.
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        {this.state.contestants.map( (m,index) =><div className='col-xs-6 col-md-3'>
                            {this.renderContestant(m,index)}
                        </div>)}
                    </div>
                    </div>
                    {this.state.contestants.filter(f=> f.Selected).length > 0 && <React.Fragment>
                        <br/>
                        <br/>
                        <br/>
                    </React.Fragment>}
                    {this.state.contestants.filter(f=> f.Selected).length > 0 && <div className='footer'>
                        <button onClick={this.onSubmitClick}>Submit</button>   
                    </div>}
                    <br/>
                    <br/>
                    <div className='info'>Winners will be chosen as per the votes and Starbucks discretion.</div>
                    <img src={secondcontent} style={{width:"90px"}}/>
                </React.Fragment>}
                
                {this.state.showThankYouMsg && <React.Fragment>

                    <div className='container'>
                        <br/>
                        <br/>
                        <br/>

                    <div className='header-text'>
                        Thank you for choosing your favourites!<br/>Stay tuned to know if they made it to our Menu!
                        {/* <h3>some additional text ...</h3> */}
                    </div>
                    </div>
                    <br/>
                    <br/>
                    <div className='info'>Winners will be chosen as per the votes and Starbucks discretion.</div>
                    <img src={secondcontent} style={{width:"90px"}}/>
                </React.Fragment>}

        </React.Fragment>
    }
}

export default Home;

